import createTsdApi from "../../../client-api/createTsdApi";
import {
  InternalTaskUpload,
  InternalTaskUploadCreateParams,
  InternalTaskUploadQueryParams,
  InternalTaskUploadUpdateParams,
} from "../../../entities/InternalTaskUpload/internalTaskUpload";
import tsdFetch, { TsdSearchParam } from "../fetch";

const internalTaskUploadApi = createTsdApi<
  InternalTaskUpload,
  InternalTaskUploadQueryParams,
  InternalTaskUploadCreateParams,
  InternalTaskUploadUpdateParams
>("internal-task-uploads", (params) => {
  const search: TsdSearchParam<InternalTaskUploadQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    search.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    search.push({ key: "offset", value: String(params.offset) });
  }

  if (typeof params.internalTaskOriginalIds !== "undefined") {
    params.internalTaskOriginalIds.forEach((id) =>
      search.push({ key: "internalTaskOriginalIds", value: String(id) })
    );
  }

  if (typeof params.uploadIds !== "undefined") {
    params.uploadIds.forEach((id) =>
      search.push({ key: "uploadIds", value: String(id) })
    );
  }

  return search;
});

type NodeFile = { data: Blob; filename: string };
const isNodeFile = (val: unknown): val is NodeFile =>
  val !== null && typeof val === "object" && "data" in val && "filename" in val;

const create = ({
  internalTaskOriginalId,
  file,
}: {
  internalTaskOriginalId: number;
  file: File | NodeFile;
}) => {
  const formData = new FormData();

  if (isNodeFile(file)) {
    formData.append("file", file.data, file.filename);
  } else {
    formData.append("file", file);
  }

  formData.append("internalTaskOriginalId", String(internalTaskOriginalId));

  const url = `/api/v1/internal-task-uploads`;
  return tsdFetch(url, {
    method: "POST",
    body: formData,
  });
};

export default { ...internalTaskUploadApi, create };
