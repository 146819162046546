import createTsdApi from "../../../client-api/createTsdApi";
import {
  PriceFile,
  PriceFileCreateParams,
  PriceFileQueryParams,
  PriceFileUpdateParams,
} from "../../../entities/priceFile";
import { TsdSearchParam } from "../fetch";

const priceFileApi = createTsdApi<
  PriceFile,
  PriceFileQueryParams,
  PriceFileCreateParams,
  PriceFileUpdateParams
>("price-files", (searchParamsObject) => {
  const searchParams: TsdSearchParam<PriceFileQueryParams>[] = [];

  if (typeof searchParamsObject.supplierIds !== "undefined") {
    searchParamsObject.supplierIds.forEach((id) => {
      searchParams.push({
        key: "supplierIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.isDefault !== "undefined") {
    searchParams.push({
      key: "isDefault",
      value: searchParamsObject.isDefault ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: searchParamsObject.isDeleted ? "1" : "0",
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  if (typeof searchParamsObject.ids !== "undefined") {
    searchParamsObject.ids.forEach((id) => {
      searchParams.push({
        key: "ids",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject["guessPriceFile.customerId"] !== "undefined") {
    searchParams.push({
      key: "guessPriceFile.customerId",
      value: String(searchParamsObject["guessPriceFile.customerId"]),
    });
  }

  if (typeof searchParamsObject["guessPriceFile.supplierId"] !== "undefined") {
    searchParams.push({
      key: "guessPriceFile.supplierId",
      value: String(searchParamsObject["guessPriceFile.supplierId"]),
    });
  }

  return searchParams;
});

export default priceFileApi;
